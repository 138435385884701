<template>
  <!-- 资料库>新增资料 loadingDetail -->
  <div class="dict" v-loading.fullscreen.lock="loadingDetail">
    <div class="form-header-btn">
      <el-page-header v-if="!detailId"
        @back="goBack"
        :content="'运营项目/'+title"
      >
        <template slot="title">
          <i class="el-icon-arrow-left"></i>返回
        </template>
      </el-page-header>
    </div>
    <el-form
      ref="ruleForm"
      style="width: 80%"
      :model="form"
      :rules="rules"
      :label-width="labelWidth"
      :disabled="isDisabled"
    >
      <el-form-item label="父级节点：" prop="parentId">
        <el-col :span="24">
          <el-cascader
            :show-all-levels="false"
            style="width:100%"
            v-model="form.parentId"
            :options="targeTypeList"
            :props="treeProps"
            placeholder="请选择"
            @change="handleChange"></el-cascader>
        </el-col>
      </el-form-item>
      <el-form-item label="项目名称：" prop="itemName">
        <el-input show-word-limit  placeholder="请输入项目名称" :maxlength="10" v-model.trim="form.itemName"></el-input>
      </el-form-item>
      <el-form-item label="项目要求：" prop="itemRequire">
        <el-input type="textarea"  :maxlength="500" show-word-limit placeholder="请输入项目要求" rows="4" v-model="form.itemRequire"></el-input>
      </el-form-item>
      <el-form-item label="岗位：" prop="jobId">
        <!-- 多选 -->
        <el-select style="width:100%" v-model="form.jobId"  multiple placeholder="请选择">
            <el-option v-for="(item,index) in  dictList.post_label" :key="index"
              :label="item.name"
              :value="item.value"
            ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="逾期反馈办理人：" prop="timeoutUserType">
        <el-select style="width:100%"  v-model="form.timeoutUserType" placeholder="请选择">
          <el-option
            v-for="(item, index) in dictList.operate_timeout_user_type"
            :key="index"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- materialId 非必填 -->
      <el-form-item label="关联资料库：" prop="">
        <el-cascader style="width:100%" 
          v-model="form.materialId"
          :show-all-levels="false"
          :options="databaseList"
          clearable
          :props="databaseProps"></el-cascader>
      </el-form-item>
      <el-form-item label="预计办理时长：" prop="predictDay">
        <el-col :span="19">
            <el-input type="number" min="0" v-model="form.predictDay"  placeholder="请输入预计办理时长"></el-input>
        </el-col>
        <el-col :span="5">
          <el-select  v-model="form.timeUnit" placeholder="请选择">
            <el-option
              label="天"
              value="2"
            ></el-option>
            <!-- <el-option
              label="小时"
              value="1"
            ></el-option> -->
          </el-select>
        </el-col>
      </el-form-item>
      <!-- sort非必填 -->
      <el-form-item label="排序：" prop="sort">
        <el-input  placeholder="请输入排序" v-model="form.sort" v-input-number  :maxlength="3" @keyup.native="getInputChange(form.sort,'sort')" ></el-input>
      </el-form-item>
      <el-form-item label="日历图标：" prop="iconPath">
        <el-popover
            placement="right-start"
            width="494"
            popper-class="opIocn"
            @show="showIcon"
            trigger="click" v-model="dialogVisible">
            <el-button slot="reference"  type="text">
                <span v-if="form.iconPath == ''">选择图标</span>
                <span v-else>更换图标</span>
            </el-button>
            <div slot="reference" v-if="form.iconPath" class="iconPathWrap" > 
                <img :src="form.iconPath.scheduleIcon" alt="">
            </div>
            <div>
                <div class="iconTitle">
                    <span>图标库</span>
                    <span class="el-icon-close" @click="dialogVisible = false"></span>
                </div>
                <div class="dialogDiv">
                    <div :class="iconPathInfo.id == item.id ?'iconPathWrap active':'iconPathWrap'" @click="chooseIconPath(item)" v-for="item in iconPathList" :key="item.id">
                    <img :src="item.scheduleIcon" alt="">
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="chooseIconPathBtn">确 定</el-button>
                </span>
            </div>
        </el-popover>
      </el-form-item>
      <el-form-item label="是否启用：" prop="isUse">
        <el-radio-group  v-model="form.isUse">
            <el-radio :label="item.value" :key="index" v-for="(item, index) in dictList.operate_is_use">{{item.name}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-divider content-position="left">办理要求</el-divider>
      <el-form-item label="完成情况：" prop="isMust">
        <el-radio-group  v-model="form.isMust">
            <el-radio :label="item.value" :key="index" v-for="(item, index) in dictList.operate_is_must">{{item.name}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="限制附件类型：" prop="isAttachmentLimit">
        <el-switch 
        v-model="form.isAttachmentLimit"
        active-text="是"
        inactive-text="否">
        </el-switch>
      </el-form-item>
      <el-form-item v-if="form.isAttachmentLimit" label="资料类型：" :prop="form.isAttachmentLimit?'attachmentType':''">
          <el-checkbox-group  v-model="form.attachmentType">
            <el-checkbox v-for="item in dictList.attachment_type" :key="item.id" :label="item.value" :value="item.value" @change="changetype">{{item.name}}</el-checkbox>
            <!-- <el-checkbox label="复选框 B"></el-checkbox>
            <el-checkbox label="复选框 C"></el-checkbox> -->
        </el-checkbox-group>
      </el-form-item>
      <el-form-item v-if="!isDisabled">
        <el-button @click="goBack">返回</el-button>
        <el-button
          type="primary"
          :loading="uploading"
          :disabled="uploading"
          @click="saveAndPublishTarget('ruleForm')"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
      <!-- :before-close="handleClose" -->
  </div>
</template>
<script>
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  props:['detailId'],
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let valueString = value + "";
      let numberAuth = /[^\d]/g;
      if (numberAuth.test(valueString)) {
        callback(new Error("只支持≥0的整数"));
      }
      //  else if (valueString.length > 1 && valueString.substr(0, 1) === "0") {
      //   callback(new Error("只支持数字，首位不能是0"));
      // } 
      else {
        callback();
      }
    };
    var checkNumPoint = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let numberAuth = /^\d*\.{0,1}\d{0,1}$/
      if (!numberAuth.test(value)) {
        callback(new Error("只支持一位小数"));
      }
      else {
        callback();
      }
    };
    return {
      dialogVisible:false,
      iconPathList:[],
      iconPathInfo:{},
      form: {
        itemName:'', //名称
        parentId:'', //父级id
        itemRequire:'',
        sort: 0, //排序
        level: "", //级别
        jobId: [], //岗位
        timeoutUserType: "", //逾期反馈办理人（1园长 2发布人）
        materialId: "", //	关联资料库ID
        // iconPath: "", //日历图标（字典表）
        iconPath: "", //日历图标（字典表）
        iconId:'',
        isMust: "", //必填
        isUse: '0', //	是否启用
        isAttachmentLimit: false, //是否限制附件类型（0否1是）
        attachmentType: [], //附件类型
        timeUnit:'2' ,//事件单位
        predictDay:'',//时长
      },
      childList: [],
      uploading: false,
      targeTypeList: [],
      deptLabelList: [],
      uploadList: [],
      rules: {
        parentId: [
          { required: true, message: "请选择父级节点", trigger: "blur" },
        ],
        itemName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
        ],
        targetName: [
          { required: true, message: "请输入项目要求", trigger: "blur" },
        ],
        itemRequire: [
          { required: true, message: "请输入项目要求", trigger: "blur" },
        ],
        jobId: [
          { required: true, message: "请选择岗位", trigger: "blur" },
        ],
        timeoutUserType: [
          { required: true, message: "请选择逾期反馈办理人", trigger: "blur" },
        ],
        predictDay: [{ required: true, message: "请输入预计办理时长", trigger: "blur" },
          { validator: checkNumPoint, trigger: 'blur' }],
        iconPath: [
          { required: true, message: "请选择日历图标", trigger: "blur" },
        ],
        isUse: [
          { required: true, message: "请选择是否启用", trigger: "blur" },
        ],
        isMust: [
          { required: true, message: "请选择完成情况", trigger: "blur" },
        ],
        attachmentType: [
          { required: true, message: "请选择资料类型", trigger: "blur" },
        ],
        sort:[
          { required: false,},
          { validator: checkNum, trigger: 'blur' }
        ]
      },
      treeProps:{
        value:'id',
        label:'itemName',
        children:'itemList',
      },
      dictList:{
        operate_timeout_user_type:[],
        operate_is_use:[],
        operate_is_must:[],
        attachment_type:[],
        post_label:[]
      },
      title:'',
      databaseList:[],
      databaseProps:{
        value:'id',
        label:'fileName',
        children:'childList',
      },
      isDisabled:false,
      itemId:0,
      iconPopervisible:false,
      jobsProps:{
        multiple: true,
        checkStrictly: true,
        value:'label',
        label:'name',
        children:'child',
      },
      loadingDetail:false,
      metaTitle:'',

      labelWidth: '150px',
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isUpdate = val.indexOf("dict:update") != -1;
          this.isAdd = val.indexOf("dict:save") != -1;
          this.isDelete = val.indexOf("dict:delete") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            console.log(data, "右侧权限按钮按钮按钮按钮啊");
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    console.log(this.$route.meta.title,'popopop')
    if(this.detailId){
      this.itemId = this.detailId
      this.title="详情"
      this.getDetail()
      this.isDisabled = true
    }else{
      this.itemId = this.$route.query.id
    }
    if(this.$route.query.isEdit == 0){
      this.title='增加'
      console.log(this.$route.meta)
      if(this.$route.query.parentId){
        this.form.parentId = (this.$route.query.parentId.split(',').map(Number))
        console.log(this.form.parentId)
      }
      this.metaTitle = '新增项目'
    }else if(this.$route.query.isEdit == 1){
      this.title='编辑'
      this.itemId = this.$route.query.id
      this.getDetail()
      this.metaTitle = '编辑项目'
    }else if(this.$route.query.isEdit == 2){
      this.title="详情"
      this.getDetail()
      this.isDisabled = true
      this.metaTitle = '项目详情'
    }
    let chooseMenuInfoList = sessionStorage.getItem("chooseMenuInfoList") ? JSON.parse(sessionStorage.getItem("chooseMenuInfoList")) : null
    if(chooseMenuInfoList){
      chooseMenuInfoList.map((item) => {
        if(item.path == this.$route.path){
          item.meta.title = this.metaTitle
        }
      })
      let breadCrumbList = JSON.parse(JSON.stringify(chooseMenuInfoList)) 
      breadCrumbList.shift()
      // console.log(breadCrumbList)
      this.$store.commit("common/getBreadcrumbList",breadCrumbList || [])
    }
    this.initData()
    this.getOperateList();
    // 字典值
    this.queryDict()
    this.initIconData()
  },
  mounted(){
    window.addEventListener("resize",(e) => {
      if(e.target.innerWidth < 1300){
        this.labelWidth = '110px'
      } else {
        this.labelWidth = (150 / 1920 * e.target.innerWidth) + 'px'
      }
      this.$forceUpdate()
    })
    if(window.innerWidth < 1300){
      this.labelWidth = '110px'
    } else {
      this.labelWidth = (150 / 1920 * window.innerWidth) + 'px'
    }
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    initData(){
      this.$api.getFileByDirectoryIdForTree().then((res) => {
        if (res.data.code == 0) {
          this.databaseList = res.data.data
        } else {
          this.$message.error(res.data.msg || "保存失败");
        }
      });
    },
    getDetail(){
      this.loadingDetail = true
      this.$api.operateDeTail(this.itemId).then((res) => {
        this.loadingDetail = false
        console.log(res)
        if (res.data.code == 0) {
          if(res.data.data.isAttachmentLimit&&res.data.data.isAttachmentLimit.value == 1){
            res.data.data.isAttachmentLimit = true
          }else{
            res.data.data.isAttachmentLimit = false
          }
          if(res.data.data.attachmentType){
            res.data.data.attachmentType = res.data.data.attachmentType.split(',')
          }else{
            res.data.data.attachmentType = []
          }
          if(res.data.data.jobId){
            res.data.data.jobId = String(res.data.data.jobId).split(',')
          }else{
            res.data.data.jobId = []
          }
          if(res.data.data.timeUnit){
            res.data.data.timeUnit = res.data.data.timeUnit.value
          }
          if(res.data.data.timeoutUserType){
            res.data.data.timeoutUserType = res.data.data.timeoutUserType.value
          }
          if(res.data.data.isMust){
            res.data.data.isMust = res.data.data.isMust.value
          }
          if(res.data.data.isUse){
            res.data.data.isUse = res.data.data.isUse.value
          }
          if(res.data.data.isCustom){
            res.data.data.isCustom = res.data.data.isCustom.value
          }
          res.data.data.parentId = res.data.data.treePath.split(',')
          res.data.data.parentId = res.data.data.parentId.map(Number)
          if(res.data.data.materialIdPath){
            res.data.data.materialId = res.data.data.materialIdPath.split(',').map(Number)
          }
          // console.log(res.data.data.materialId.map(Number))
          this.form = res.data.data
          console.log(this.form)
          this.getIconPath(this.form.iconPath)
        } else {
          this.$message.error(res.data.msg || "保存失败");
        }
      });
    },
    initIconData(){
      this.$api.getAllIconList().then(res=>{
        if(res.data.code == '0'){
          this.iconPathList = res.data.data
          // console.log(res.data.data)
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    chooseIconPath(item){
      this.iconPathInfo = item
    },
    chooseIconPathBtn(){
      this.form.iconPath = this.iconPathInfo
      // this.form.iconId = this.iconPathInfo.id
      this.dialogVisible = false
    },
    queryDict() {
      var dict = Object.keys(this.dictList);
      let requests = dict.map(
        (item) =>
          new Promise((resolve, reject) => {
            this.$api
              .getDictionary({ type: item })
              .then((res) => {
                res.data.fieldName = item;
                resolve(res.data);
              })
              .catch(() => {
                resolve([]);
              });
          })
      );
      Promise.all(requests).then((res) => {
        res.forEach((item) => {
          this.dictList[item.fieldName] = item.data;
        });
      });
    },
    //改变输入按键事件
    getInputChange(value,type){
      let resultValue = value.replace(/[^\w\.\/]/ig,'')
      this.form[type] = resultValue
    },
    handleChange(val){
    },
    getOperateList() {
      this.$api.getOperateList({
        itemName:''
      }).then((res) => {
        if (res.data.code == 0) {
          // this.targeTypeList = res.data.data;
          this.targeTypeList = []
          if(res.data.data){
            res.data.data.forEach(e=>{
              if(e.itemList){
                this.targeTypeList.push(e)
                e.itemList.forEach(ele=>{
                  ele.itemList = null
                })
              }else{
                e.itemList = null
              }
            })
          }
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    changetype(val){
      console.log(val)
    },
    showIcon(){
      if(this.form.iconPath){
        this.iconPathInfo = this.form.iconPath
      }else{
        this.iconPathInfo = ''
      }
    },
    saveAndPublishTarget(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let parmas = JSON.parse(JSON.stringify(this.form));
          parmas.jobId = parmas.jobId.join(",")
          if(parmas.isAttachmentLimit){
            parmas.attachmentType = parmas.attachmentType.join(",")
          }else{
            parmas.attachmentType = ''
          }
          parmas.isAttachmentLimit = parmas.isAttachmentLimit?1:0
          if(typeof parmas.parentId == 'object'){
            parmas.parentId = parmas.parentId[parmas.parentId.length-1]
          }
          if(typeof parmas.materialId == 'object' ){
            if( parmas.materialId == null ||  parmas.materialId.length == 0){
              parmas.materialId = ''
            }else{
              parmas.materialId = parmas.materialId[parmas.materialId.length-1]
              parmas.materialIdPath = this.form.materialId.join(',')
            }
          }
          parmas.iconPath = parmas.iconPath.id
          console.log(parmas,'bianji ')
          if(this.$route.query.isEdit == 0){
            this.$api.operateSave(parmas).then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "保存成功",
                });
                this.goBack();
              } else {
                this.$message.error(res.data.msg || "保存失败");
              }
            });
          }else if(this.$route.query.isEdit == 1){
            this.$api.operateEdit(parmas).then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "保存成功",
                });
                this.goBack();
              } else {
                this.$message.error(res.data.msg || "保存失败");
              }
            });
          }
        } else {
          // this.$message.error("请完善信息");
          return false;
        }
      });
    },
    getIconPath(id){
      this.$api.getIconDetail(id).then(res=>{
        if(res.data.code == '0'){
          console.log(res.data.data)
          this.form.iconPath = res.data.data
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
  },
  beforeDestroy(){
    window.removeEventListener("resize",(e) => {
      if(e.target.innerWidth < 1300){
        this.labelWidth = '110px'
      } else {
        this.labelWidth = (150 / 1920 * e.target.innerWidth) + 'px'
      }
      this.$forceUpdate()
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
  background-color: #ffffff;
  height: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  .form-header-btn{
    margin-bottom: 20px;
  }
  .tableList {
    height: calc(100% - 62px);
    
  }
}
.iconPathWrap{
  width: 50px;
  height: 50px;
  background: #EAF1FC;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
  img{
    width: 24px;
    height: 24px;
  }
}
/deep/.el-dialog__header{
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
/deep/.el-dialog__footer{
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
.dialogDiv{
  width:100%;
  display: flex;
  flex-wrap: wrap;
  padding: 24px 18px;
  box-sizing: border-box;
  .iconPathWrap{
    background: #fff;
    border: 1px solid #fff;
    cursor: pointer;
  }
  .active{
    border: 1px solid #003685;
  }
}
.icon-list{
  display: flex;
  flex-wrap: wrap;
  .icon-item{
    margin-right: 6px;
  }
}
.dialogDiv{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    padding: 24px 18px;
    box-sizing: border-box;
}
/deep/.el-dialog__header{
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.dialog-footer{
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}
.iconTitle{
    display: flex;
    justify-content: space-between;
    padding: 2px 28px 14px;
    align-items: center;
    color: #3D3E4F;
    font-size: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    .el-icon-close{
        color: #909399;
        font-size: 14px;
        cursor: pointer;
    }
}
</style>
<style>
.opIocn{
  padding: 10px 0;
  z-index: 1900 !important;
}
</style>
